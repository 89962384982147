div#root {
    margin: 0px;
    width: 100%;
}

.navbar-dark .nav-link {
    color: rgba(255, 255, 255, .55);
}

div.tooltip {
    inset: auto !important;
}

div.tooltip {
    inset: auto !important;
}
